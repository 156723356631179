import React from "react";
import { useEffect, useState } from 'react';
import PageLayout from "../../layouts/PageLayout";
import { DivideTitle } from "../../components";
import { Row, Col } from "react-bootstrap";
import { CardHeader } from "../../components/cards";
import { LabelField } from "../../components/fields";
import { Box, Button } from "../../components/elements";
import { BrowserProvider, Contract, parseUnits, formatUnits, formatEther, JsonRpcProvider } from 'ethers';
import { createWeb3Modal, defaultConfig, useWeb3Modal, useWeb3ModalAccount, useWeb3ModalProvider, useSwitchNetwork } from '@web3modal/ethers/react';

export default function CoreToken() {

    const contractAddress = "0x74775eAb03E010a5024408e6a4989aDd56e01183";
    const abi = [{"constant":false,"inputs":[{"internalType":"address","name":"cOwner","type":"address"},{"internalType":"string","name":"name","type":"string"},{"internalType":"string","name":"symbol","type":"string"},{"internalType":"uint256","name":"totalSupply","type":"uint256"}],"name":"createToken","outputs":[{"internalType":"bool","name":"","type":"bool"}],"payable":true,"stateMutability":"payable","type":"function"},{"inputs":[{"internalType":"address payable","name":"_theOwner","type":"address"},{"internalType":"uint256","name":"fee","type":"uint256"}],"payable":false,"stateMutability":"nonpayable","type":"constructor"},{"constant":false,"inputs":[{"internalType":"address payable","name":"beneficiary","type":"address"}],"name":"withdrawEther","outputs":[],"payable":false,"stateMutability":"nonpayable","type":"function"},{"constant":true,"inputs":[],"name":"theOwner","outputs":[{"internalType":"address payable","name":"","type":"address"}],"payable":false,"stateMutability":"view","type":"function"}];
    const fee = "5000000000000000000";

    // 4. Use modal hook
  const { open } = useWeb3Modal();
  const { address, chainId, isConnected } = useWeb3ModalAccount();
  const { walletProvider } = useWeb3ModalProvider();
  const { switchNetwork } = useSwitchNetwork();

    const [nameText, setNameText] = React.useState(null);
    const [symbolText, setSymbolText] = React.useState(null);
    const [supplyText, setSupplyText] = React.useState(null);
    const [ownerText, setOwnerText] = React.useState(null);

    const handleNameChange = (event) => {
        setNameText(event.target.value);
      };

      const handleSymbolChange = (event) => {
        setSymbolText(event.target.value);
      };

      const handleSupplyChange = (event) => {
        setSupplyText(event.target.value);
      };

      const handleOwnerChange = (event) => {
        setOwnerText(event.target.value);
      };





      const connectWalletButton = () => {
        return (
          <Button onClick={() => open()} icon="wallet" text="Wallet Connect" className="mc-btn primary m-2" />
        )
      }

      const createButton = () => {
        return (
          <Button onClick={createToken} icon="add" text="Create Token" className="mc-btn green m-2" />
        )
      }

      useEffect(() => {

      }, [])

      const createToken = async() => {
        try{
          

          if(isConnected){
            const provider = new BrowserProvider(walletProvider);
            const signer = await provider.getSigner();
            const contract = new Contract(contractAddress, abi, signer);

            console.log("Intialize payment");
            let createToken = await contract.createToken(ownerText, nameText, symbolText, supplyText, {value: fee});
            console.log(contract);
        if(createToken){
          alert("Congratulations, your token has been created on Core Network.");
        }else{
          alert("Something wrong, Some fields missing or maybe you don't have enough CORE balance for transaction fee (fee = 5 CORE).");
        }
          }
        }catch(err){
        alert("Something wrong, Some fields missing or maybe you don't have enough CORE balance for transaction fee (fee = 5 CORE).");
          console.log(err);
        }

      }

    return (
        <PageLayout>
            <Row>
            <Col xl={12}>
                    <Box className="mc-card">
                        <CardHeader title="Create Token On Core Network" />
                        <Row xs={12} md={12} xl={12}>
                            <Col>
                                <DivideTitle title=" " className="mb-4 mt-3" />
                                <LabelField onChange={handleNameChange} value={nameText} label="Token Name" type="text" fieldSize="w-100 h-lg" labelDir="label-col mb-4" placeholder="Bitcoin" />
                                <LabelField onChange={handleSymbolChange} value={symbolText} label="Symbol" type="text" fieldSize="w-100 h-lg" labelDir="label-col mb-4" placeholder="BTC" />
                                <LabelField onChange={handleSupplyChange} value={supplyText} label="Supply" type="number" min="1" fieldSize="w-100 h-lg" labelDir="label-col mb-4" placeholder="21000000" />
                                <LabelField onChange={handleOwnerChange} value={ownerText} label="Owner Wallet" type="text" fieldSize="w-100 h-lg" labelDir="label-col mb-4" placeholder="0x............" />
                            </Col>
                        </Row>
                    </Box>
                </Col>
                <Col xl={12}>
                    <Box className="mb-4">
                        <Row xs={12} md={3} xl={3} className="justify-content-center">
                          {isConnected ? createButton() : connectWalletButton()}
                        </Row>
                    </Box>
                </Col>
            </Row>
        </PageLayout>
    )
}
